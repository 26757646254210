.authContainer {
    width: 100%;
    position: relative;
    display: table;
}

.authContainer form {
    display: table;
    margin: 40px auto;
    /*border: 1px solid #002060;*/
    border-radius: 5px;
    padding: 40px;
    background-color: #e8f2fa;
    /*max-width: 400px;*/
}
/*.authContainer form input[type="text"], .authContainer form input[type="password"]{
    width: 100%;
    border: 1px solid #ccc;
    padding: 4px 15px;
    border-radius: 3px;
    margin-bottom: 15px;
}
*/


.authContainer form button {
    width: 100%;
}

.authContainer .logoContainer {
    width: 100px;
    margin: 0 auto 30px;
    display: table;
}