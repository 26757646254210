.inputWrapper input {
    /*width: 100%;*/
    outline: none;
    /*padding: 2px 5px;*/
    /*border-radius: 3px;*/
    /*border: none;*/
}

.inputWrapper.padded input {
    padding: 7px 15px;
}

.inputWrapper input:focus {
    /*border: 1px solid rgba(0, 118, 189, 0.5);*/
    box-shadow: 0 0 3px #629DD1;
}

.inputWrapper {
    /*width: 100%;*/
    /*border: 1px solid #ccc;*/
    border-radius: 3px;
    /*margin-bottom: 15px;*/
    position: relative;
}

.inputWrapper.error {
    border:1px solid #9C3A71;
}

.inputWrapper.info {
    border:1px solid #246A9A;
}


.inputWrapper label {
    /*color: #666;*/
    font-weight: bolder;
    font-size: 12px;
    /*background: rgba(255,255,255,1);*/
    right: 4px;
    line-height: 12px;
    top: 2px;
    /*background: #DDD;*/
    width: 100%;
    display: block;
    padding: 1px 0 2px 15px;

}

