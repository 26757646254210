.inputMsg:before {
  position: absolute;
  content: "";
  border-top: 10px solid transparent;
  bottom: 100%;
  left: 0;
}
.error.inputMsg:before {
  border-left: 10px solid #9C3A71;
}

.info.inputMsg:before {
  border-left: 10px solid #246A9A;
}

.inputMsg {
  position: absolute;
  display: none;
  left: 10px;
  top: 100%;
  padding: 3px 10px;
  z-index: 1;
}

.error.inputMsg{
  color: #FFFFFF;
  background: #9C3A71;
  display: inline-block;
}

.info.inputMsg{
  color: #FFFFFF;
  background: #246A9A;
  display: inline-block;
}