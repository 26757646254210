button.blue, button.outline {
    /*font-weight: bold;*/
    
    /*padding: 4px 9px;*/
    /*border-radius: 3px;*/
    /*position: relative;*/
    /*border: 1px solid #629DD1;*/
}

/*button.blue.outline {*/
/*    background-color: #FFFFFF;*/
/*    color: #629DD1;*/
/*    border-color: #629DD1;*/
/*}*/
/*button.blue {*/
/*    background-color: #629DD1;*/
/*    color: white;*/
/*}*/

button.blue:hover {
    background-color: #3F88C7;
}

button.blue.outline:hover {
    background-color: #e8f2fa;
}

button.blue.loading:after,
button.outline.loading:after,
.blueButton.loading.after,
.outlineBlueButton.loading.after
{
    position: absolute;
    
    content: "";
    width: 40px;
    height: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

button.blue.loading:after, .blueButton.loading.after {
    background: url(img/loader.svg) center center no-repeat;
}
button.blue.outline.loading:after, .outlineBlueButton.loading.after {
    background: url(img/loaderblue.svg) center center no-repeat;
}


button.blue.loading:before ,button.outline.loading:before {
    position: absolute;
    content: "";
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 80%;
}

button.blue.loading:before {
    background-color: #B0CEE8;
}

button.blue.outline.loading:before {
    background-color: #FFF;
}

button.blue.disabled, button.blue.loading, .blueButton.loading, .blueButton.disabled, .blueButton.disabled:hover {
    background-color: #B0CEE8;
    cursor: default;
}

.blueButton.loading:hover,.blueButton.disabled:hover {
    background-color: #B0CEE8;
}

button.blue.outline.disabled {
    background-color: #F1F2F3;
    /*border-color: #AAA;*/
    color:#AAA;
}