:root {
    --uh-green:#8fbc8f;
    --uh-blue:#629DD1;
    --uh-blue-light:#e8f2fa;
    --uh-blue-dark:#3F88C7;
    --uh-blue-darkest:#246A9A;
    --uh-notification-info: #246A9A;
    --uh-notification-success: #8DBC58;
    --uh-notification-error:#9c3a71;
}

a, a:hover {
    color: var(--uh-blue);
}
a {
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
html {
    line-height: 16px;
}
body{
    font:.9rem/1.5 'Garamond', 'Georgia', serif!important;
    /*font-size: 14px;*/
    line-height: .9rem;
    letter-spacing: .3px;
}



nav .logo {
    max-width: 250px;
}

nav a.active {
    border-bottom: 3px solid var(--uh-blue);
}




footer .logo {
    width: 70px;
}

.mainContent {

    min-height: 450px;
    max-width: 100%;
    /*margin: 0 40px;*/
    /*padding-bottom:  10rem;*/
}

.entryTable th, .entryTable td {
    font-family: inherit;
    padding: 2px 5px;

}

.entryTable th {
    font-weight: bold;
    padding: 2px 5px;
}



input{
    color: inherit;
}

a.blue {
    color: var(--uh-blue);
}


#root {
    position: relative;
    min-height: 100vh;
}

footer {
    position: absolute;
    bottom: 0;
    width:100%;
}

w-100 {
    width: 25em;
}

img.loader.mainLoader {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%,0%);
}
img.loader {
    display: table;
    margin: 0 auto;
}

img.loader.small {
    width: 40px;
}

.allCenter {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.notification{
    min-height: 70px;
    z-index: 2147483647;
}

