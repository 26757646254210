:root{
    --toastify-icon-color-success:#FFF;
    --toastify-color-progress-success:#FFF;
    --toastify-icon-color-error:#FFF;
    --toastify-color-progress-error:#FFF;
    --toastify-icon-color-info:#FFF;
    --toastify-color-progress-info:#FFF;
}
.notification-info, .notification-error, .notification-success {
    color:#FFF;
    font-weight: bold;
}

.notification-info {
    background-color: var(--uh-notification-info);
}
.notification-error {
    background-color: var(--uh-notification-error);
}
.notification-success {
    background-color: var(--uh-notification-success);
}


.Toastify__close-button--light {
    color: #FFF;
    opacity: .8;
}
